.inputBox {
  display: flex;
  margin: .25em;
}

.inputContainer {
  background-color: white;
  border-radius: 0 5px 5px 0;
  padding: .5em .5em;
}

.Lift {
  width: 8em;
}

.Weight {
  width: 2em;
}