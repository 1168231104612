.history {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 100%;
  max-width: 630px;
  margin: 0 auto;
}

#logoutBtn {
  justify-self: center;
}