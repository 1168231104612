@use "variables";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: variables.$background-gray;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

label {
  color: white;
  padding: .5em 1em;
  background-color: black;
  border-radius: 5px 0 0 5px;
}

input {
  font-family: inherit;
  font-size: inherit;
  border: none;
  width: 1.4em;
  height: 1.1em;
}

@media (max-width: 400px) {  
  body {
    font-size: .85rem;
  }
}