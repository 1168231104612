.timerDisplay {
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 0;
  height: 1.25em;
  background-color: white;
  border-radius: 5px;
  font-size: 4.25rem;
  font-weight: 700;
}

@media (max-width: 400px) {  
  .timerDisplay {
    font-size: 3.6rem;
  }
}